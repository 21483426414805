import { ChangeDetectionStrategy, Component, computed, effect, inject, Input, OnInit, signal } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { customPhoneValidator } from '../shared/validators/phone-validator';
import { customDateValidator } from '../shared/validators/date-validator';
import { ReferenceDataService } from '../shared/services/reference-data.service';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Category } from '../models/category.model';
import { DateMaskDirective } from '../shared/directives/date-mask.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteModule, MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { LossReportForm } from './loss-report-form.model';
import { FoundItem } from '../models/found-item.model';
import { AsyncPipe, NgClass } from '@angular/common';
import { combineLatest, filter } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Line } from '../models/line.model';
import { LoadingService } from '../shared/services/loading.service';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../environments/environment';
import { MatIcon } from '@angular/material/icon';
import { DragDropDirective } from '../shared/directives/drag-drop.directive';

@Component({
  selector: 'app-loss-report-form',
  standalone: true,
  imports: [
    DateMaskDirective,
    FormsModule,
    MatButtonModule,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatAutocompleteModule,
    NgClass,
    MatIcon,
    DragDropDirective
  ],
  template: `
    <form class="w-full" [formGroup]="lossReportForm">
      <h2 class="text-xl mt-8 mb-6 font-semibold text-black">Informations sur l'objet</h2>
      <mat-form-field appearance="outline">
        <mat-label>Catégorie de l'objet</mat-label>
        <mat-select formControlName="category">
          @for (category of categories$ | async; track category.id) {
            <mat-option [value]="category.id">
              {{ category.name }}
            </mat-option>
          } @empty {
            <mat-option disabled>En cours de chargement...</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (subcategories(); as subcategories) {
        <mat-form-field appearance="outline">
          <mat-label>Sous-catégorie de l'objet</mat-label>
          <mat-select formControlName="subcategory">
            @for (category of subcategories; track category.id) {
              <mat-option [value]="category.id">
                {{ category.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <mat-form-field appearance="outline">
        <mat-label>Couleur de l'objet</mat-label>
        <mat-select formControlName="color">
          @for (color of colors(); track color.value) {
            <mat-option [value]="color.value">
              {{ color.label }}
            </mat-option>
          } @empty {
            <mat-option disabled>En cours de chargement...</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Décrivez l'objet</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>


      <h2 class="text-xl mt-8 mb-6 font-semibold text-black">Informations sur l'incident</h2>
      <mat-form-field appearance="outline">
        <mat-label>Date de la perte ?</mat-label>
        <input matInput appDateMask [matDatepicker]="picker" [minLength]="10"
               [maxLength]="10" formControlName="lossDate">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Sur quelle ligne ?</mat-label>
        <input type="text"
               placeholder="Sélectionnez une ligne"
               aria-label="Ligne"
               matInput
               formControlName="line"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayLineFn">
          @for (line of filteredLines(); track line.id) {
            <mat-option [value]="line">{{ line.name }}</mat-option>
          } @empty {
            <mat-option disabled>En cours de chargement...</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>

      @if (directions(); as directions) {
        <mat-form-field appearance="outline">
          <mat-label>Dans quelle direction ?</mat-label>
          <mat-select formControlName="lineDirection">
            @for (direction of directions; track direction) {
              <mat-option [value]="direction">
                {{ direction }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <h2 class="text-xl font-semibold text-black mt-8 mb-6">Informations personnelles</h2>
      <mat-form-field appearance="outline">
        <mat-label>Nom</mat-label>
        <input matInput formControlName="lastname">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Prénom</mat-label>
        <input matInput formControlName="firstname">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Téléphone</mat-label>
        <input matInput type="tel" formControlName="phone">
      </mat-form-field>

      <h2 class="text-xl font-semibold text-black mt-8 mb-6">Pièce d’identité ou passeport</h2>
      <p class="mb-4 text-gray-500 text-center text-sm">Vous pouvez fournir une copie d’une pièce d’identité afin de prouver votre identité et d’accélérer la restitution de votre objet</p>
      <div class="file-input">
        @for (file of lossReportForm.controls.identityDocuments.value; track file.name) {
          <div class="flex items-center border-[1px] border-gray-300 rounded mb-4 pl-2 font-medium text-black">
            <div class="flex-1">{{ file.name }}</div>
            <button mat-icon-button class="flex items-center text-gray-500" (click)="deleteFile(file)"
                    [disabled]="loadingService.isLoading()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        }
        <input #fileInput type="file" [disabled]="loadingService.isLoading()"
               (click)="lossReportForm.controls.identityDocuments.markAsTouched()"
               accept=".jpg, .jpeg, .png" class="hidden"
               (change)="onFileSelected($event)" multiple required>
        <label (click)="fileInput.click()"
               appDragDrop
               (onFileDropped)="onFileSelected($event)"
               for="file"
               [ngClass]="[
                   lossReportForm.controls.identityDocuments.invalid && (lossReportForm.controls.identityDocuments.dirty || lossReportForm.controls.identityDocuments.touched) ? '!text-red-400 border-red-400 bg-red-100' : '',
                   loadingService.isLoading() ? 'opacity-50 pointer-events-none' : ''
                 ]"
               class="mb-4 flex flex-col items-center justify-center border-2 border-dashed text-gray-300 border-gray-300 rounded-lg cursor-pointer h-40 hover-effect">
          <mat-icon class="!size-auto text-primary overflow-visible text-3xl pointer-events-none"
                    fontSet="material-icons-outlined">attachment
          </mat-icon>
          <div class="text-black font-medium text-center">Glissez-déposez des fichiers ou <span
            class="text-primary underline">Parcourir</span>
          </div>
          <div class="text-gray-500 text-center text-sm">Formats supportés: JPG, JPEG, PNG</div>
        </label>
      </div>

      <section formGroupName="contactPreferences" class="mt-8 mb-6">
        <mat-label class="block lg:inline text-black"> Je veux être contacté(e) par :</mat-label>
        <mat-checkbox color="primary" formControlName="contactByEmail">Email</mat-checkbox>
        <mat-checkbox color="primary" formControlName="contactByPhone">Téléphone</mat-checkbox>
      </section>
    </form>

    @if (!environment.production) {
      <button mat-flat-button color="primary" (click)="mockForm()"> Mock</button>
    }
  `,
  styles: `
    mat-form-field {
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LossReportFormComponent implements OnInit {
  @Input({ required: true }) foundItem!: FoundItem | undefined;

  private fb = inject(FormBuilder);
  private referenceDataService = inject(ReferenceDataService);
  loadingService = inject(LoadingService);


  categories$ = toObservable(this.referenceDataService.categories);
  colors = this.referenceDataService.colors;
  subcategories = signal<Category[] | null>(null);
  lossReportForm = this.fb.group({
    // Personal information
    lastname: this.fb.nonNullable.control('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), noNumberValidator()]),
    firstname: this.fb.nonNullable.control('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), noNumberValidator()]),
    email: this.fb.nonNullable.control('', [Validators.required, Validators.email, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
    phone: this.fb.nonNullable.control('', [Validators.required, customPhoneValidator()]),
    contactPreferences: this.fb.group({
      contactByEmail: [true],
      contactByPhone: [false]
    }, { validators: [requireCheckboxesToBeCheckedValidator(1), Validators.required] }),
    // Item information
    category: this.fb.control<number | null>(null, [Validators.required]),
    subcategory: this.fb.control<number | null>(null, [Validators.required]),
    color: this.fb.nonNullable.control<string>('', [Validators.required]),
    description: this.fb.nonNullable.control<string>('', [Validators.minLength(3), Validators.maxLength(5000)]),
    // Incident information
    lossDate: this.fb.nonNullable.control<Date>(new Date(), [Validators.required, customDateValidator()]),
    line: this.fb.control<Line | null>(null, [customLineValidator(this.referenceDataService.lines())]),
    lineDirection: this.fb.control<string | null>(null),
    identityDocuments: this.fb.control<File[]>([]) // File will be handled separately
  });
  directions = signal<any | null>(null);
  #lineTermsFilter = toSignal(
    this.lossReportForm.controls.line.valueChanges.pipe(
      map((value: Line | string | null) => (typeof value === 'string' ? value : value?.name)?.toLowerCase())
    )
  );
  filteredLines = computed(() => {
    const lineTermsFilter = this.#lineTermsFilter();
    const lines = this.referenceDataService.lines();
    if (lineTermsFilter) {
      return lines?.filter((line) => line.name.toLowerCase().includes(lineTermsFilter));
    }
    return lines;
  });

  constructor() {
    const categoryFormControl$ = this.lossReportForm.controls.category.valueChanges.pipe(startWith(this.lossReportForm.controls.category.value));
    combineLatest([categoryFormControl$, this.categories$])
      .pipe(
        filter(([categoryId, categories]) => !!categoryId && !!categories),
        map(([categoryId, categories]) => {
          if (categoryId === null) return null;
          return categories!.find((category) => category.id === categoryId)?.subcategories ?? null;
        }),
        takeUntilDestroyed()
      )
      .subscribe((subcategories) => {
        this.subcategories.set(subcategories);
      });

    effect(() => {
      const lines = this.referenceDataService.lines();
      if (!lines) return;
      if (lines.length > 0) {
        this.lossReportForm.controls.line.setValidators(customLineValidator(lines));
        // Pour que le champ apparaisse en rouge dès la première édition si la lign est invalide
        this.lossReportForm.controls.line.markAsTouched();
      }
    });
    this.lossReportForm.controls.line.valueChanges.subscribe((line) => {
      if (line) {
        this.directions.set(line.directions);
      } else {
        this.directions.set(null);
      }
      this.lossReportForm.controls.lineDirection.reset();
    });
  }

  ngOnInit(): void {
    if (this.foundItem) {
      this.lossReportForm.patchValue({
        category: this.foundItem.category.id,
        subcategory: this.foundItem.category.subcategories![0]?.id ?? '',
        color: this.foundItem.color.value
      });
      this.lossReportForm.controls.category.disable();
      if (this.lossReportForm.controls.subcategory.value) {
        this.lossReportForm.controls.subcategory.disable();
      }
      if (this.lossReportForm.controls.color.value) {
        this.lossReportForm.controls.color.disable();
      }
    }
  }

  /**
   * Il y a des valeurs nullable dans les formControls pour autoriser les valeurs initiales à null.
   * Cependant, pour récupérer les valeurs brutes, il faut que le formulaire soit valide.
   * Et ces champs sont tous requis, donc le formulaire ne sera jamais valide si on ne les remplit pas.
   */
  getRawValue(): LossReportForm | null {
    if (this.lossReportForm.invalid) return null;
    const rawValue = this.lossReportForm.getRawValue();
    const form: LossReportForm = {
      identityDocuments: rawValue.identityDocuments ?? [],
      phone: rawValue.phone!,
      email: rawValue.email!,
      firstname: rawValue.firstname!,
      lastname: rawValue.lastname!,
      description: rawValue.description!,
      color: rawValue.color!,
      category: rawValue.category!,
      subcategory: rawValue.subcategory!,
      lossDate: rawValue.lossDate ?? undefined,
      contactPreferences: {
        contactByEmail: rawValue.contactPreferences.contactByEmail!,
        contactByPhone: rawValue.contactPreferences.contactByPhone!
      }
    };
    if (rawValue.line) {
      form.line = {
        id: rawValue.line.id,
        direction: rawValue.lineDirection ?? undefined
      };
    }
    return form;
  }

  displayLineFn(line: Line): string {
    return line && line.name ? line.name : '';
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (!files) return;

    const fileArray = Array.from(files);
    const images = fileArray.filter(file => file.type.startsWith('image/'));

    const existingDocuments = this.lossReportForm.value.identityDocuments || [];
    const filteredDocuments = existingDocuments.filter(existingFile =>
      !images.some(newFile => newFile.name === existingFile.name)
    );
    const updatedDocuments = [...filteredDocuments, ...images];
    this.lossReportForm.patchValue({ identityDocuments: updatedDocuments });
    this.lossReportForm.controls.identityDocuments.markAsDirty();
  }

  deleteFile(fileToDelete: File) {
    const updatedDocuments = this.lossReportForm.controls.identityDocuments.value?.filter((file: File) => file.name !== fileToDelete.name);
    this.lossReportForm.patchValue({ identityDocuments: updatedDocuments });
    this.lossReportForm.controls.identityDocuments.markAsDirty();
  }

  protected readonly environment = environment;

  mockForm(): void {
    this.lossReportForm.setValue({
      // Personal information
      lastname: 'Doe',
      firstname: 'John',
      email: 'desogus.maxime@moviplus.ch',
      phone: '111 111 11 11',
      contactPreferences: {
        contactByEmail: true,
        contactByPhone: false
      },
      // Item information
      category: 2127,  // Assuming 1 is a valid category id
      subcategory: 2500,  // Assuming 1 is a valid subcategory id
      color: 'RED',
      description: 'Description of the lost item.',
      // Incident information
      lossDate: new Date('2023-01-01'),
      line: this.referenceDataService.lines()![0],  // Assuming the first line is a valid line
      lineDirection: this.referenceDataService.lines()![0].directions[0],
      identityDocuments: []
    });
  }
}

function noNumberValidator(): ValidatorFn {
  return (control: AbstractControl<string>): { [key: string]: any } | null => {
    const value = control.value;
    if (/\d/.test(value)) { // checks if value contains any number
      return { 'numbersNotAllowed': true }; // return error if it does
    }
    return null; // return null if value is valid
  };
}

function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Vérifier si le contrôle est bien un FormGroup
    if (!(control instanceof FormGroup)) return null;
    let checked = 0;

    Object.keys(control.controls).forEach(key => {
      if (control.controls[key].value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return { requireOneCheckboxToBeChecked: true };
    }

    return null;
  }
}

function customLineValidator(lines: Line[] | undefined): ValidatorFn {
  return (control: AbstractControl<Line>): { [key: string]: any } | null => {
    const value = control.value;
    if (!lines || !value) return null;
    if (!lines.find((line) => line.id === value.id)) {
      return { 'lineNotFound': true };
    }
    return null; // return null if value is valid
  };
}
